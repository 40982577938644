import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import ModalBox from '../../ui-core/Modal';
import Button from '../../ui-core/Button/Button';
import StartFreeTrial from './StartFreeTrial';
import SubscriptionPlans from './SubscriptionPlans';
import { toggleTrialModal } from '../../store/action/common';
import {
  doFetchProductsRequest,
  doPaymentInitiationRequest,
} from '../../store/action/products';
import { IMAGE_CONSTANT, TEXT_CONSTANT } from '../../constants/app';
import { withRouter } from 'react-router-dom';
import { SEGMENT_EVENTS, trackEvent } from '../../utils/Segment';
import { encodeBase64Value } from '../../utils/url';
import { APP_ROUTES } from '../../route';

import './PaymentModal.css';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const PaymentFooter = props => {
  const { isLoading, selectedPlan } = useSelector(state => state.product);
  const dispatch = useDispatch();
  const params = useParams();
  const { curatedPlans } = useSelector(state => state.plan);

  const handleSubmit = () => {
    if (!props.showSubscription) {
      trackEvent(SEGMENT_EVENTS.FREE_TRIAL_STARTED, {
        pricing_plan: selectedPlan?.attributes?.name,
        pricing_amount: +selectedPlan?.attributes?.amount_in_cents,
      });
    }
    dispatch(
      doPaymentInitiationRequest({
        page: encodeBase64Value(APP_ROUTES.FREE_TRAIL),
        plan_id: params.id,
      }),
    );
    const curatePlanDetails = {
      title: curatedPlans.title,
      id: curatedPlans.id,
      card_color: curatedPlans.card_color,
    };
    localStorage.setItem(
      'curatePlanDetails',
      JSON.stringify(curatePlanDetails),
    );
  };
  return (
    <>
      <Modal.Footer className="start-free-trail-footer">
        <div className="m-auto w-100 text-center">
          <Button
            class="start-free-trail-btn"
            isDisabled={!selectedPlan}
            isLoading={isLoading}
            onClick={handleSubmit}
            text={
              props.showSubscription
                ? TEXT_CONSTANT.JOIN_MEMBERSHIP
                : TEXT_CONSTANT.START_FREE_TRAIL
            }
          />
        </div>
      </Modal.Footer>
    </>
  );
};
PaymentFooter.propTypes = {
  history: PropTypes.object,
  page: PropTypes.string,
  showSubscription: PropTypes.bool,
};

const PaymentModal = ({ history }) => {
  const { openTrialModal } = useSelector(state => state.common);
  const { open, viewAll, page } = openTrialModal || {};
  const dispatch = useDispatch();

  const renderHeader = () => (
    <h2 className="mx-auto payment-modal-title">
      {viewAll ? 'Subscription Plans' : 'How your free trial works?'}
    </h2>
  );
  const toggleViewAll = () => {
    const newValue = !viewAll;
    if (newValue) trackEvent(SEGMENT_EVENTS.VIEW_PRICING_PLANS);
    dispatch(toggleTrialModal({ open, viewAll: newValue }));
  };

  const renderModalBody = () => {
    if (viewAll) {
      return <SubscriptionPlans />;
    } else {
      return (
        <StartFreeTrial
          toggleViewAll={toggleViewAll}
          showSubscription={viewAll}
        />
      );
    }
  };

  const handleClose = () =>
    dispatch(toggleTrialModal({ open: false, viewAll: false }));

  useEffect(() => {
    if (open) {
      dispatch(doFetchProductsRequest());
    }
  }, [open]);

  return (
    <ModalBox
      backButton={viewAll && !openTrialModal?.hideBackButton}
      closeButton
      customBackIcon={IMAGE_CONSTANT.backIcon}
      handleClose={handleClose}
      handleGoBack={toggleViewAll}
      dialogClassName={viewAll ? 'subscription-modal' : 'payment-modal'}
      bsPrefix="payment-modal-wrapper"
      show={open}
      head={renderHeader()}
      footer={
        <PaymentFooter
          history={history}
          page={page}
          showSubscription={viewAll}
        />
      }
    >
      {renderModalBody()}
    </ModalBox>
  );
};

PaymentModal.propTypes = {
  history: PropTypes.object,
};

export default withRouter(PaymentModal);
