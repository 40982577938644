import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { extractJSON } from '../../services/jsonUtil';
import { removeItem } from '../../utils/cache';
import {
  analyticsReset,
  identifyUser,
  SEGMENT_EVENTS,
  trackEvent,
} from '../../utils/Segment';
import { getDisplayType, getUserAttribute } from '../../utils/helpers';
import useWindowWidth from '../../hooks/useWindowWidth';
import {
  IMAGE_CONSTANT,
  LOCAL_STORAGE_CONSTANT,
  TEXT_CONSTANT,
} from '../../constants/app';
import { APP_ROUTES, getRoute } from '../../route';
import {
  doGetUserDataRequest,
  doUpdateUserProfieRequest,
} from '../../store/action/user';
import {
  checkForChange,
  resetStore,
  setSelectedFilters,
  setValuesAreChanged,
} from '../../store/action/common';
import { doPlansSuccess } from '../../store/action/plan';
import PersonalDetails from './PersonalDetails';
import HelpAndSupport from './HelpAndSupport';
import ModalBox from '../../ui-core/Modal';

import './style.css';
import Payments from './Payments';
import MyOrders from './MyOrders';

const Profile = ({ history, match }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const displayType = getDisplayType(useWindowWidth());
  const isDesktopScreen = displayType === 'desktop';
  const section = match.params.section;
  const onboardingCompleted = getUserAttribute('onboarding_completed');
  const userName = getUserAttribute('name');
  const [show, setShow] = useState(false);
  const nextKeyRef = useRef(null);
  const profilePic = getUserAttribute('profile_pic');

  useEffect(() => {
    const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
    if (user?.id) {
      const userId = user.id;
      dispatch(doGetUserDataRequest({ userId }));
    }
  }, []);

  const handleSectionChange = (section = null) => {
    if (section) {
      const options = {
        pathParam: {
          section,
        },
      };
      const url = getRoute(APP_ROUTES.PROFILE_WITH_SUB_ROUTES, options);
      history.replace(url);
    } else {
      history.replace(APP_ROUTES.PROFILE);
    }
  };

  const handleSubmit = (data, callback) => {
    const userId = user?.id;
    identifyUser(userId, {
      country_code: data?.country_code,
      phone: data?.mobile,
      email: data?.email,
      name: data?.name,
      gender: data?.gender,
    });
    trackEvent(SEGMENT_EVENTS.PROFILE_UPDATED, {
      user_id: user?.id,
      user_email: data?.email,
      user_gender: data?.gender,
      user_name: data?.name,
      user_phone: data?.mobile,
      free_trial_active: user?.attributes?.free_trial || false,
      subscription_active:
        user?.attributes?.subscription_details?.subscribed || false,
    });
    dispatch(
      doUpdateUserProfieRequest({
        data,
        callback: () => {
          dispatch(setValuesAreChanged(false));
          callback && callback();
        },
      }),
    );
  };

  function callback(value, key = null) {
    if (value) {
      setShow(true);
      nextKeyRef.current = key;
    } else {
      handleSectionChange(key);
    }
  }

  const handleClick = key => {
    switch (key) {
      case 'logout':
        logOut();
        break;
      default:
        dispatch(
          checkForChange({
            callback: value => callback(value, key),
          }),
        );
        break;
    }
  };
  const logOut = () => {
    trackEvent(SEGMENT_EVENTS.LOGOUT, {
      user_id: user?.id,
      free_trial_active: user?.attributes?.free_trial || false,
      subscription_active:
        user?.attributes?.subscription_details?.subscribed || false,
    });
    dispatch(resetStore());
    dispatch(setSelectedFilters({ selectedFilters: [], skipSave: true }));
    dispatch(doPlansSuccess([]));
    removeItem(LOCAL_STORAGE_CONSTANT.USER);
    removeItem(LOCAL_STORAGE_CONSTANT.SESSION);
    removeItem(LOCAL_STORAGE_CONSTANT.SELECTED_FILTERS);
    removeItem(LOCAL_STORAGE_CONSTANT.LAST_VOLUME);
    removeItem(LOCAL_STORAGE_CONSTANT.LAST_PLAYBACK_RATE);
    removeItem(LOCAL_STORAGE_CONSTANT.BODY_PARTS_OPTIONS);
    removeItem('curatePlanDetails');
    history.push(APP_ROUTES.ROOT);
    analyticsReset();
    window?.Cohere?.stop();
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  };

  const handleBundleDealsRedirection = () => {
    history.push(APP_ROUTES.BUNDLE_DEALS);
  };

  const renderContent = () => {
    switch (section) {
      case 'help-support':
        return <HelpAndSupport />;
      case 'payments':
        return <Payments />;
      case 'my-orders':
        return (
          <MyOrders
            handleBundleDealsRedirection={handleBundleDealsRedirection}
          />
        );
      // case 'about-jen':
      //   return null;
      case 'edit-profile':
        return (
          <>
            {user ? (
              <PersonalDetails user={user} handleSubmit={handleSubmit} />
            ) : (
              <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                Retrieving details
                <Spinner className="mx-2" animation="border" />
              </div>
            )}
          </>
        );
      default:
        return (
          <>
            {user ? (
              <PersonalDetails user={user} handleSubmit={handleSubmit} />
            ) : (
              <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                Retrieving details
                <Spinner className="mx-2" animation="border" />
              </div>
            )}
          </>
        );
    }
  };

  const defaultBlock = () => (
    <div className="bg-white">
      <div className="bg-floral pt-lg-5">
        <h1 className="text-center list-page-title pt-4 mb-0 my-edit-profile-text">
          {' '}
          My Profile{' '}
        </h1>
      </div>
      <div className="folder-wrapper body-col-3 p-0">
        <div className="">
          <div className="profile-page-container d-flex flex-wrap">
            <div className="profile-left">
              <SideBar
                handleClick={handleClick}
                handleUpload={handleSubmit}
                profilePicture={user?.attributes?.profile_pic || profilePic}
                selectedOption={section || 'edit-profile'}
                userName={user?.attributes?.name || userName}
              />
            </div>
            <div className="profile-right">{renderContent()}</div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderMobileContent = () => {
    switch (section) {
      case 'help-support':
      case 'payments':
      case 'edit-profile':
      case 'my-orders':
        // case 'about-jen':
        return renderContent();
      default:
        return defaultBlock();
    }
  };

  const renderHeaderTitle = () => {
    switch (section) {
      case 'help-support':
        return 'Help & Support';
      case 'payments':
        return 'Payments';
      case 'edit-profile':
        return 'Edit Profile';
      case 'my-orders':
        return 'My Orders';
      // case 'about-jen':
      //   return 'About Jen';
      default:
        break;
    }
  };

  const handleGoBack = () => history.goBack();

  const goBackMobile = () => {
    dispatch(
      checkForChange({
        callback,
      }),
    );
  };

  const discardChanges = discard => {
    if (discard) {
      handleSectionChange(nextKeyRef.current);
      dispatch(setValuesAreChanged(false));
    }
    setShow(false);
  };

  return (
    <div className="profile-page">
      {isDesktopScreen && (
        <Header
          homeClass="home"
          challengeClass="challenge"
          foldersClass="folders"
          hideBackButton={true}
          discoverClass="discover"
        />
      )}
      {isDesktopScreen ? (
        defaultBlock()
      ) : (
        <>
          {!section && !onboardingCompleted && (
            <div className="d-flex justify-content-between align-items-center profile-header-block">
              <img
                className="cursor-pointer"
                src={IMAGE_CONSTANT.backIcon}
                alt="back"
                onClick={handleGoBack}
              />
            </div>
          )}
          {section && (
            <div className="d-flex justify-content-between align-items-center profile-header-block">
              <img
                className="cursor-pointer"
                src={IMAGE_CONSTANT.backIcon}
                alt="back"
                onClick={goBackMobile}
              />
              <h5 className="mb-0">{renderHeaderTitle()}</h5>
              <div></div>
            </div>
          )}
          {renderMobileContent()}
        </>
      )}
      <ModalBox backdrop={'static'} show={show}>
        <div className="discard-changes mt-2">
          <h5 className="text-center">{TEXT_CONSTANT.DISCARD_CHANGES}</h5>
          <div className="d-flex w-100 justify-content-center">
            <Button
              onClick={() => discardChanges(true)}
              variant="danger"
              className="mx-2 cursor-pointer"
            >
              {TEXT_CONSTANT.YES}
            </Button>
            <Button
              onClick={() => discardChanges()}
              variant="dark"
              className="mx-2 cursor-pointer"
            >
              {TEXT_CONSTANT.NO}
            </Button>
          </div>
        </div>
      </ModalBox>
    </div>
  );
};

Profile.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default Profile;
