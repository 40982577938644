import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import parser from 'html-react-parser';

import LazyImage from '../../../../ui-core/Image';

import './style.css';

export const Steps = ({ backgroundColor, headerText, items = [] }) => {
  return (
    <div className="lead-steps-section" style={{ backgroundColor }}>
      <h3>{headerText}</h3>
      <Row>
        {items.map((item, index) => (
          <Col md={4} key={`lead-steps-${index + 1}`}>
            <div className="lead-steps-item">
              <div className="position-relative">
                <LazyImage
                  alt={item.title}
                  className="img-fluid"
                  errorSrc={item.png}
                  placeholderSrc={item.jpg}
                  src={item.webp}
                />
                <h3
                  style={{ backgroundColor: item.backgroundColor }}
                  className="heading"
                >
                  <span
                    style={{ color: item.backgroundColor }}
                    className="count"
                  >
                    {index + 1}
                  </span>
                  <span>{item.title}</span>
                </h3>
              </div>
              <p className="description">{parser(item.description)}</p>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

Steps.propTypes = {
  backgroundColor: PropTypes.string,
  headerText: PropTypes.string,
  items: PropTypes.array.isRequired,
};
